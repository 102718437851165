import { forwardRef, useEffect, useState } from "react";

const Popup = forwardRef((props, ref) => {
  const [user, setUser] = useState(null);
  const [supervisor, setSupervisor] = useState(null);

  useEffect(() => {
    if (props.item) {
      fetch(`/api/mobile/${props.item.UserID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setUser(data);
        })
        .catch((e) => {});
    }
  }, [props.item]);

  useEffect(() => {
    if (props.item) {
      fetch(`/api/mobile/${props.item.SupervisorID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setSupervisor(data);
        })
        .catch((e) => {});
    }
  }, [props.item]);

  return (
    <div ref={ref} className="cpopup">
      <div className="container">
        <div className="ctop">
          <h3>Activity Details</h3>
          <i
            className="fa fa-times"
            onClick={() => {
              props.setPopup(null);
            }}
          />
        </div>
        <div className="div2equal">
          <div>
            <h4>Details</h4>
            <hr />
            <p>
              <b>Task: </b>
              {props?.item?.Task}
            </p>
            <p>
              <b>Type: </b>
              {props?.item?.Type}
            </p>
            <p>
              <b>Date: </b>
              {props?.item?.Date}
            </p>
            <p>
              <b>SubCounty: </b>
              {props?.item?.SubCounty}
            </p>
            <p>
              <b>Ward: </b>
              {props?.item?.Ward}
            </p>
          </div>
          <div>
            <h4>Officer</h4>
            <hr />
            <p>
              <b>Name: </b>
              {user ? user?.Name:""}
            </p>
            <p>
              <b>Phone: </b>
              {user ? user?.Phone:""}
            </p>
            <p>
              <b>Role: </b>
              {user ? user?.Role :""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Popup;
