import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import { GrUserAdmin } from "react-icons/gr";
import { FaFish } from "react-icons/fa";
import { FaUserDoctor, FaWheatAwn } from "react-icons/fa6";
import { GiBullHorns } from "react-icons/gi";

export default function Reports(props) {
  const [start, setStart] = useState("2024-01-01");
  const [end, setEnd] = useState("");
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [todaysData, setTodaysData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/workplan/completed/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [refresh, offset]);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/workplan/completed/today/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setTodaysData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [refresh, offset]);

  function searchReport(q) {
    setLoading(true);
    fetch(`/api/workplan/serachcompleted/${q}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div className="list">
      <div className="myreports">
        <div className="summary">
          <div className="box">
            <div className="single">
              <FaWheatAwn className="ts" />
              <div>
                <h4>{todaysData && todaysData.total}</h4>
                <p>Agriculture</p>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="single">
              <GiBullHorns className="ts" />
              <div>
                <h4>{todaysData && todaysData.total}</h4>
                <p>Livestock</p>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="single">
              <FaUserDoctor className="ts" />
              <div>
                <h4>{todaysData && todaysData.total}</h4>
                <p>Veterinary</p>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="single">
              <FaFish className="ts" />
              <div>
                <h4>{todaysData && todaysData.total}</h4>
                <p>Fisheries</p>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="single">
              <GrUserAdmin className="ts" />
              <div>
                <h4>{todaysData && todaysData.total}</h4>
                <p>Administration </p>
              </div>
            </div>
          </div>
        </div>
        <div className="top">
          <div className="search">
            <input
              onChange={(e) => {
                if (e.target.value != "") {
                  searchReport(e.target.value);
                } else {
                  setRefresh(!refresh);
                }
              }}
              type="text"
              name=""
              id=""
              placeholder="Task | Officer | Supervisor"
            />
            <i className="fa fa-search"></i>
          </div>
          <input
            onChange={(e) => {
              setStart(e.target.value);
            }}
            type="date"
            value={start}
          />
          <input
            onChange={(e) => {
              setEnd(e.target.value);
            }}
            type="date"
            value={end}
          />
        </div>
        <div className="my_list">
          <h4>Today's Reports</h4>
          {todaysData && todaysData?.data?.length > 0 ? (
            todaysData.data.map((item, i) => {
              return <Item key={i} index={offset + i + 1} item={item} />;
            })
          ) : (
            <p>
              <em>No data</em>
            </p>
          )}
        </div>
        <div className="my_list">
          <h4>All Reports</h4>
          {data && data?.data?.length > 0 ? (
            data.data.map((item, i) => {
              return <Item key={i} index={offset + i + 1} item={item} />;
            })
          ) : (
            <p className="no-data">
              <em>No data</em>
            </p>
          )}
        </div>
        <Pagination
          totalItems={data ? data.total : 0}
          currentPage={offset}
          onPageChange={(v) => {
            setOffset(v);
          }}
        />
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <div className="item">
      <h6>{props.index}</h6>
      <div>
        <h4>{props.item.Task}</h4>
      </div>
      <p>{props.item.Role}</p>
      <div>
        <p>{props.item.SubCounty}</p>
        <p>{props.item.Ward}</p>
      </div>
      <div>
        <p>{props.item.Name}</p>
        <p>{props.item.Phone}</p>
      </div>
      <h6>{props.item.Date}</h6>
    </div>
  );
};
