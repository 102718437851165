import { useState } from "react";
import logo from "../../assets/imgs/logo.png";

export default function Navigation(props) {
  const pathname = window.location.pathname.split("/");

  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
        onMouseLeave={() => {
          setShowing(false);
        }}
      >
        <div
          onClick={() => {
            if (params.options.length === 0) {
              window.location.href = params.url;
            }
            if (params.url == "/logout") {
              localStorage.clear();
              window.location.href = "/login";
            }
          }}
          onMouseEnter={() => {
            setShowing(true);
          }}
          className={params.link === params.active ? "active-item" : "item"}
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "auto 1fr auto" : "auto",
          }}
        >
          <i aria-hidden="true" className={"fa " + params.icon}>
            {params.code}
          </i>
          {params.showing && <p>{params.txt}</p>}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ width: props.showing ? "250px" : "fit-content" }}
      className="navigation"
    >
      <div className="logo">
        <img
          style={{ maxWidth: props.showing ? "70px" : "30px" }}
          src={logo}
          alt=""
        />
        {props.showing && (
          <>
            <h3> KiriAMIS </h3>
            <h3>Staff Portal</h3>
            <hr />
          </>
        )}
      </div>
      <Item
        url="/"
        txt="Home"
        active={pathname[1]}
        link=""
        icon="fa-home"
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/activities"
        txt="Daily Activities"
        active={pathname[1]}
        link="activities"
        icon="fa-pie-chart"
        code="&#xf200;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Extension Services"
        url="/es"
        icon="fas fa-toolbox"
        active={pathname[1]}
        link="es"
        options={[]}
        showing={props.showing}
      />

      <Item
        url="/portal-updates"
        txt="Portal Updates"
        active={pathname[1]}
        link="portal-updates"
        icon="fas fa-newspaper"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Users"
        url="/users"
        active={pathname[1]}
        link="users"
        icon="fa-user"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Settings"
        url="/settings"
        active={pathname[1]}
        link="settings"
        icon="fa-gear"
        code="&#xf013;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Logout"
        active={pathname[1]}
        link="logout"
        icon="fa-lock"
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
}
