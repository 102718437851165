import "../Styles/home.scss";

import { useEffect, useState } from "react";
import Settings from "../components/Settings/Settings";
import UserHome from "../components/Users/UserHome";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import PortalUpdates from "../components/PortalUpdates/PortalUpdates";
import ActivitiesHome from "../components/Activities/ActivitiesHome";
import ReportsHome from "../components/Reports/ReportsHome";
import NewStats from "../components/Stats/NewStats";
import { isMobile } from "react-device-detect";
import ActiveUsers from "../components/Stats/ActiveUsers";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="home">
      <div
        style={
          isMobile
            ? {}
            : { gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }
        }
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <NewStats showing={showing} />}

            {pathname[1] == "portal-updates" && <PortalUpdates />}

            {pathname[1] === "users" && <UserHome />}
            {pathname[1] === "activities" && <ActivitiesHome />}
            {pathname[1] === "es" && <ReportsHome />}
            {pathname[1] === "active" && <ActiveUsers />}

            {pathname[1] === "settings" && <Settings />}
          </div>
        </div>
      </div>
    </div>
  );
}
