import Map from "ol/Map";
import View from "ol/View";
import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import GeoJSON from "ol/format/GeoJSON";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle.js";
import { useEffect, useRef, useState } from "react";
import RippleLoading from "../Util/RippleLoading";
import Popup from "./Popup.jsx";

export default function MyMap(props) {
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [dataLayer, setDataLayer] = useState(
    new VectorLayer({ title: "Activities" })
  );
  const [map, setMap] = useState(null);
  const [popup, setPopup] = useState(null);
  const [start, setStart] = useState("2024-01-01");
  const [end, setEnd] = useState("");
  const mapElement = useRef();
  const { myData } = require("../../assets/data/data.js");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const today = new Date();
    const m = today.getMonth() + 1;
    const date = `${today.getFullYear()}-${
      m < 10 ? "0" + m : m
    }-${today.getDate()}`;
    console.log(date);
    setEnd(date);
    basemap.setSource(
      new XYZ({
        url: myData[0].url,
        crossOrigin: "anonymous",
      })
    );
    const initialMap = new Map({
      target: mapElement.current,
      layers: [basemap, dataLayer],
      view: new View({
        projection: "EPSG:4326",
        center: [36.45, -0.1],
        zoom: 12,
        maxZoom: 20,
      }),
      controls: defaultControls().extend([
        new ZoomToExtent({
          extent: [34.36168, 0.41839, 35.06887, 1.14702],
        }),
        new ScaleLine({
          units: "metric",
          bar: false,
          text: "Scale",
        }),
      ]),
    });
    initialMap.on("click", function (evt) {
      let features = [];
      initialMap.forEachFeatureAtPixel(
        evt.pixel,
        function (clickedFeature, layer) {
          const attributes = clickedFeature?.getProperties();
          features.push(attributes);
        }
      );
      if (features.length > 0) {
        setPopup(features[0]);
      }
    });
    setMap(initialMap);
    return () => {
      initialMap.setTarget(null);
    };
  }, [basemap, dataLayer, myData]);

  useEffect(() => {
    if (start != "" && end != "") {
      fetchAll(start, end);
    }
  }, [start, end, refresh]);

  function fetchAll(start, end) {
    dataLayer.setSource(new VectorSource());
    setLoading(true);
    fetch(`/api/activity/geojson/${start}/${end}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data && data?.length > 0) {
          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(data[0].geojson, {
              dataProjection: "EPSG:4326",
              featureProjection: "EPSG:4326",
            }),
          });

          dataLayer.setSource(vectorSource);
          dataLayer.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 15,
                stroke: new Stroke({
                  color: "red",
                  width: 2,
                }),
                fill: new Fill({
                  color: "yellow",
                }),
              }),
            })
          );
          map.getView().fit(vectorSource.getExtent());
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  function searchGeojson(q) {
    dataLayer.setSource(new VectorSource());
    setLoading(true);
    fetch(`/api/activity/searchgeojson/${q}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data && data?.length > 0) {
          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(data[0].geojson, {
              dataProjection: "EPSG:4326",
              featureProjection: "EPSG:4326",
            }),
          });

          dataLayer.setSource(vectorSource);
          dataLayer.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 15,
                stroke: new Stroke({
                  color: "red",
                  width: 2,
                }),
                fill: new Fill({
                  color: "yellow",
                }),
              }),
            })
          );
          map.getView().fit(vectorSource.getExtent());
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <div className="list">
      <div className="map">
        <div className="top">
          <div className="search">
            <input
              onChange={(e) => {
                if (e.target.value != "") {
                  searchGeojson(e.target.value);
                } else {
                  setRefresh(!refresh);
                }
              }}
              type="text"
              name=""
              id=""
              placeholder="Task | Officer |Supervisor"
            />
            <i className="fa fa-search"></i>
          </div>
          <input
            onChange={(e) => {
              setStart(e.target.value);
            }}
            type="date"
            value={start}
          />
          <input
            onChange={(e) => {
              setEnd(e.target.value);
            }}
            type="date"
            value={end}
          />
        </div>
        <div ref={mapElement} className="map-element">
          {loading && <RippleLoading />}
        </div>
        {popup && <Popup item={popup} setPopup={setPopup} />}
      </div>
    </div>
  );
}
