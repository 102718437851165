import React, { useEffect, useRef, useState } from "react";
import "../../Styles/active.scss";
import { FaDownload, FaSearch } from "react-icons/fa";
import WaveLoading from "../Util/WaveLoading";
import Pagination from "../Util/Pagination";

export default function ActiveUsers(props) {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [activities, setActivities] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    setStart(formattedDate);
    setEnd(formattedDate);
  }, []);

  useEffect(() => {
    if (start !== "" && end !== "") {
      getData();
    }
  }, [start, end, refresh, offset]);

  const multipliedOffset = offset * 12; // Multiply offset by 12

  function getData() {
    setLoading(true);

    fetch(`/api/logs/today/${start}/${end}/${multipliedOffset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed!");
      })
      .then((data) => {
        setActivities(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function search(q) {
    setLoading(true);
    fetch(`/api/logs/search/${q}/${multipliedOffset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setActivities(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function downloadData() {
    if (activities && activities.data) {
      const csvData = convertToCSV(activities.data);
      downloadCSV(csvData);
    }
  }

  function convertToCSV(data) {
    const headers = ["No", "Name", "Phone", "Day", "Login Time"];
    const rows = data.map((item, index) => [
      multipliedOffset + index + 1,
      item.Name,
      item.Phone,
      item.Day,
      item.LoginTime,
    ]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((e) => e.join(",")).join("\n");

    return csvContent;
  }

  function downloadCSV(csvContent) {
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "activities.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const act = useRef();

  return (
    <div className="stats">
      <div className="activities" ref={act}>
        <div className="bar">
          <div className="l-bar"></div>
          <h2>Logged In Users</h2>

          <div className="div1auto">
            <div className="search">
              <input
                onChange={(e) => {
                  if (e.target.value !== "") {
                    search(e.target.value);
                  } else {
                    setRefresh(!refresh);
                  }
                }}
                type="text"
                placeholder="Search By Name..."
              />
              <FaSearch className="fa-search" />
            </div>
            <div className="dates-2">
              <input
                onChange={(e) => {
                  setStart(e.target.value);
                }}
                type="date"
                value={start}
              />
              <input
                onChange={(e) => {
                  setEnd(e.target.value);
                }}
                type="date"
                value={end}
              />
              <FaDownload className="fa-download" onClick={downloadData} />
            </div>
          </div>
        </div>
        <div className="head">
          <p>No.</p>
          <p>Name</p>
          <p>Phone</p>
          <p>Day</p>
          <p>Login Time</p>
        </div>
        <div className="my_list">
          {activities &&
            activities?.data?.length > 0 &&
            activities.data.map((item, i) => {
              return (
                <Item key={i} index={multipliedOffset + i + 1} item={item} />
              );
            })}
        </div>
        <Pagination
          totalItems={activities ? activities.total : 0}
          currentPage={offset}
          onPageChange={(v) => {
            setOffset(v);
          }}
        />
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("#fff");
  const [clicked, setClicked] = useState(false);

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  useEffect(() => {
    if ((props.index + 1) % 2 == 0) {
      setColor("#feece2");
    }
  }, []);

  return (
    <>
      <div
        className="row"
        style={{ backgroundColor: color }}
        onClick={() => setClicked(true)}
      >
        <p>{props.index}</p>
        <div>
          <h4>{capitalizeFirstLetter(props?.item?.Name)}</h4>
        </div>
        <div>
          <p style={{ fontSize: "small" }}>
            {capitalizeFirstLetter(props?.item?.Phone)}
          </p>
        </div>
        <p>{props.item.Day}</p>
        <div>
          <h4>{capitalizeFirstLetter(props?.item?.LoginTime)}</h4>
        </div>
      </div>

      {/* {clicked && <PopUp setClicked={setClicked} item={props.item} />} */}
    </>
  );
};
