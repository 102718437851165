import { off } from "process";
import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";

export default function Reports(props) {
  const [start, setStart] = useState("2024-01-01");
  const [end, setEnd] = useState("2024-12-31");
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/activity/paginated/${start}/${end}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [refresh, offset]);

  function searchReport(q) {
    setLoading(true);
    fetch(`/api/activity/search/${q}/${offset * 12}`)
    .then((res) => {
      if (res.ok) return res.json();
      else throw Error("");
    })
    .then((data) => {
      setLoading(false);
      setData(data);
    })
    .catch((error) => {
      setLoading(false);
    })
  };

  return (
    <div className="list">
      <div className="myreports">
        <div className="top">
          <div className="search">
            <input
              onChange={(e) => {
                if (e.target.value != "") {
                  searchReport(e.target.value);
                } else {
                  setRefresh(!refresh);
                }
              }}
              type="text"
              name=""
              id=""
              placeholder="Task | Officer |Supervisor"
            />
            <i className="fa fa-search"></i>
          </div>
          <input
            onChange={(e) => {
              setStart(e.target.value);
            }}
            type="date"
            value={start}
          />
          <input
            onChange={(e) => {
              setEnd(e.target.value);
            }}
            type="date"
            value={end}
          />
        </div>
        <div className="my_list">
          {data &&
            data?.data?.length > 0 &&
            data.data.map((item, i) => {
              return <Item key={i} index={offset + i + 1} item={item} />;
            })}
        </div>
        <Pagination
          totalItems={data ? data.total : 0}
          currentPage={offset}
          onPageChange={(v) => {
            setOffset(v);
          }}
        />
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <div className="item">
      <h6>{props.index}</h6>
      <div>
        <h4>{props.item.Task}</h4>
        <p>{props.item.Type}</p>
      </div>

      <div>
        <p>{props.item.SubCounty}</p>
        <p>{props.item.Ward}</p>
      </div>
      <div>
        <p>{props.item.Name}</p>
        <p>{props.item.Phone}</p>
      </div>
      <h6>{props.item.Date}</h6>
    </div>
  );
};
