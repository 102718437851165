import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/imgs/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

export default function Header(props) {
  var jwt = require("jsonwebtoken");
  const [nav, setNav] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [clicked, setClicked] = useState(false);

  const toggleMenu = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const token = localStorage.getItem("gdfhgfhtkn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);
        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [isAuthenticated]);

  return (
    <div>
      <div className="header">
        <i
          className="fa fa-bars"
          onClick={() => {
            setNav(true);
            props.setShowing(!props.showing);
          }}
        ></i>
        <div className="right">
          <p>{currentUser.Name}</p>
          <i className="fa fa-user"></i>
        </div>
      </div>

      {nav && <MobileHeader setNav={setNav} />}
    </div>
  );
}

const MobileHeader = (props) => {
  const pathname = window.location.pathname.split("/");

  return (
    <div className="mobheader">
      <FontAwesomeIcon
        onClick={() => {
          props.setNav(false);
        }}
        icon={faTimes}
        className="fa-times"
      />
      <img src={logo} alt="" />
      <h1>KiriAMIS Staff Portal</h1>

      <hr />
      <Item
        url="/"
        txt="Home"
        active={pathname[1]}
        link=""
        icon="fa-home"
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/activities"
        txt="Daily Activities"
        active={pathname[1]}
        link="activities"
        icon="fa-pie-chart"
        code="&#xf200;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Extension Services"
        url="/es"
        icon="fas fa-toolbox"
        active={pathname[1]}
        link="es"
        options={[]}
        showing={props.showing}
      />

      <Item
        url="/portal-updates"
        txt="Portal Updates"
        active={pathname[1]}
        link="portal-updates"
        icon="fas fa-newspaper"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Users"
        url="/users"
        active={pathname[1]}
        link="users"
        icon="fa-user"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Settings"
        url="/settings"
        active={pathname[1]}
        link="settings"
        icon="fa-gear"
        code="&#xf013;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Logout"
        active={pathname[1]}
        link="logout"
        icon="fa-lock"
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
};

const Item = (params) => {
  const [showing, setShowing] = useState(false);

  return (
    <div
      style={{
        backgroundColor: showing ? "#60606010" : "transparent",
      }}
      onMouseLeave={() => {
        setShowing(false);
      }}
    >
      <div
        onClick={() => {
          if (params.options.length === 0) {
            window.location.href = params.url;
          }
          if (params.url == "/logout") {
            localStorage.clear();
            window.location.href = "/login";
          }
        }}
        onMouseEnter={() => {
          setShowing(true);
        }}
        className={params.link === params.active ? "active-item" : "item"}
        style={{
          padding: params.showing ? "1em" : "5x 0 5px 0",
        }}
      >
        <i aria-hidden="true" className={"fa " + params.icon}>
          {params.code}
        </i>
        <p>{params.txt}</p>
      </div>
    </div>
  );
};
