import React, { useEffect, useRef, useState } from "react";
import "../../Styles/newstats.scss";
import { FiRefreshCcw } from "react-icons/fi";
import { FaHelmetSafety } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import CustomBarChart from "./CustomBarChart";
import GaugeChart from "react-gauge-chart";
import { MdOutlineNewLabel } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import WaveLoading from "../Util/WaveLoading";
import Pagination from "../Util/Pagination";
import ClickableBarChart from "./ClickableBarChart";

export default function NewStats(props) {
  const today = new Date();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [staff, setStaff] = useState(null);
  const [wrdstaff, setWrdStaff] = useState(null);
  const [sbcounty, setSbCounty] = useState(null);
  const [start, setStart] = useState(
    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  );
  const [end, setEnd] = useState(
    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  );
  const [activities, setActivities] = useState(null);
  const [offset, setOffset] = useState(0);
  const [activeToday, setActiveToday] = useState(0);

  useEffect(() => {
    if (start !== "" && end !== "") {
      getData();
    }
  }, [start, end, refresh, offset]);

  const multipliedOffset = offset * 12;

  useEffect(() => {
    setLoading(true);
    fetch(`/api/activity/paginated/${start}/${end}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setActivities(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [refresh, offset, start, end, offset]);

  useEffect(() => {
    setLoading(true);
    const today = new Date();

    fetch(
      `/api/mobile/active/${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setActiveToday(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/mobile/staff/sbcounty`, {
      method: "GET",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setStaff(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (staff && Array.isArray(staff.sb) && staff.sb.length > 0) {
      setSbCounty(staff.sb[0]?.name);
    }
  }, [staff]);

  useEffect(() => {
    if (sbcounty) {
      fetch(`/api/mobile/staff/ward/${sbcounty}`, {
        method: "GET",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setWrdStaff(data);
        })
        .catch((err) => {});
    }
  }, [sbcounty]);

  function getData() {
    setLoading(true);
    fetch(`/api/activity/home/charts/${start}/${end}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed!");
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function search(q) {
    setLoading(true);
    fetch(`/api/activity/search/${q}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setActivities(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (start !== "" && end !== "") {
      getData();
    }
  }, [start, end, offset]);

  const act = useRef();

  // Event handler for clicking on a bar in the bar chart
  const handleBarClick = (value) => {
    setSbCounty(value);
  };

  return (
    <div className="stats">
      <div className="welcome">
        <div>
          <h1>Kirinyaga Dashboard</h1>
        </div>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </div>

      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Daily Performance</h2>
          </div>

          <div className="outer">
            <div className="ll">
              <div
                className="section cursor"
                onClick={() => {
                  window.location.href = "/active";
                }}
              >
                <div className="single">
                  <GrUserWorker className="ts" />
                  <div>
                    <h4>{activeToday ? activeToday.data : 0}</h4>
                    <p>Today's Active Staff</p>
                  </div>
                </div>
              </div>
              <div
                className="section cursor"
                onClick={() =>
                  act.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                <div className="single">
                  <MdOutlineNewLabel className="ts" />
                  <div>
                    <h4>{data ? data?.Tasks : 0}</h4>
                    <p>Today's Activities</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Today's Activities vs Active Staff</h3>
              <GaugeChart
                className="gauge"
                id="gauge-chart2"
                nrOfLevels={3}
                colors={["red", "orange", "green"]}
                arcWidth={0.3}
                percent={
                  data
                    ? (
                        parseInt(activeToday?.data) / parseInt(data?.Tasks)
                      ).toFixed(2)
                    : 0
                }
                textColor="gray"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Distribution of Staff Activites by Sub County</h2>
          </div>
          <div className="section">
            <CustomBarChart
              aspect={2.6}
              data={data ? data.SubCounty : null}
              color="orange"
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2> Distribution of Staff Activities by Type</h2>
          </div>
          <div className="section">
            <CustomBarChart
              aspect={1.6}
              data={data ? data.Type : null}
              color="orange"
            />
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2> Distribution of Staff Activities by Ward</h2>
          </div>

          <div className="section">
            <CustomBarChart
              aspect={1.6}
              data={data ? data.Ward : null}
              color="orange"
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>No of Staff at Sub County Level</h2>
          </div>
          <div className="section">
            {staff && staff.sb ? (
              <ClickableBarChart
                aspect={1.6}
                data={staff.sb}
                color="orange"
                onBarClick={handleBarClick}
              />
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            {<h2>No of Staff at Ward Level for {sbcounty} Sub County</h2>}
          </div>

          <div className="section">
            {wrdstaff && wrdstaff.wrd ? (
              <CustomBarChart aspect={1.6} data={wrdstaff.wrd} color="orange" />
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="activities" ref={act}>
        <div className="bar">
          <div className="l-bar"></div>
          <h2>Today's Staff Activities</h2>

          <div className="div1auto">
            <div className="search">
              <input
                onChange={(e) => {
                  if (e.target.value !== "") {
                    search(e.target.value);
                  } else {
                    setRefresh(!refresh);
                  }
                }}
                type="text"
                placeholder="Search By... Task | Description | Officer"
              />
              <FaSearch className="fa-search" />
            </div>
            <div className="dates">
              <input
                onChange={(e) => {
                  setStart(e.target.value);
                }}
                type="date"
                value={start}
              />
              <input
                onChange={(e) => {
                  setEnd(e.target.value);
                }}
                type="date"
                value={end}
              />
            </div>
          </div>
        </div>
        <div className="head">
          <p>No.</p>
          <p>Task & Venue</p>
          <p>Officer</p>
          <p>Date</p>
          <p>Level</p>
          <p>Level Name</p>
        </div>
        <div className="my_list">
          {activities &&
            activities?.data?.length > 0 &&
            activities.data.map((item, i) => {
              return (
                <Item key={i} index={multipliedOffset + i + 1} item={item} />
              );
            })}
        </div>
        <Pagination
          totalItems={activities ? activities.total : 0}
          currentPage={offset}
          onPageChange={(v) => {
            setOffset(v);
          }}
        />
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("#fff");
  const [clicked, setClicked] = useState(false);

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  useEffect(() => {
    if ((props.index + 1) % 2 == 0) {
      setColor("#feece2");
    }
  }, []);

  return (
    <>
      <div
        className="row"
        style={{ backgroundColor: color }}
        onClick={() => setClicked(true)}
      >
        <p>{props.index}</p>
        <div>
          <h4>{capitalizeFirstLetter(props?.item?.Task)}</h4>
          <p style={{ fontSize: "small" }}>
            {capitalizeFirstLetter(props?.item?.Venue)}
          </p>
        </div>
        <div>
          <p style={{ fontSize: "small" }}>
            {capitalizeFirstLetter(props?.item?.Name)}
          </p>
          <p style={{ fontSize: "small" }}>{props.item.Phone}</p>
        </div>
        <p>{props.item.Date}</p>

        <p>{props.item.Level}</p>
        <p>{props.item.LevelName}</p>
      </div>

      {/* {clicked && <PopUp setClicked={setClicked} item={props.item} />} */}
    </>
  );
};
