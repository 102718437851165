import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/users.scss";
import Button from "../Util/Button";
import Input from "./UserInput";
import WaveLoading from "../Util/WaveLoading";
import Pagination from "../Util/Pagination";
import UserSelect from "./UserSelect";
import SelectedUser from "./SelectedUser";
import UserBox from "./UserBox";

export default function PortalUsers() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [userID, setUserID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const selected = useRef();

  useEffect(() => {
    setLoading(true);
    fetch(`/api/auth/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data.result.length > 0) {
          setUserID(data.result[0].UserID);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [refresh, offset]);

  useEffect(() => {
    selectedUser();
  }, [userID]);

  const selectedUser = () => {
    setLoading(true);
    fetch(`/api/auth/${userID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setUserDetails(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function quickSearch(value) {
    setData(null);
    setLoading(true);
    fetch(`/api/auth/quicksearch/${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        console.log(data);
        if (data.result.length > 0) {
          setUserID(data.result[0].UserID);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div className="users">
      <div className="list">
        <div className="utp">
          <h3>Portal Users</h3>
          <p
            onClick={() => {
              setClicked(true);
            }}
          >
            <FontAwesomeIcon className="fa-add" icon={faUserPlus} /> New User
          </p>
          <div className="search">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Name..."
              onChange={(e) => {
                const v = e.target.value;
                if (v !== "") {
                  quickSearch(v);
                } else {
                  setRefresh(!refresh);
                }
              }}
            />
            <FontAwesomeIcon className="fa-search" icon={faSearch} />
          </div>
        </div>
        <hr />

        <div className="div31">
          <div>
            <div className="lcontainer">
              <div className="user-list">
                {data &&
                  data?.result?.length > 0 &&
                  data?.result?.map((item, index) => {
                    return (
                      <UserBox
                        key={index}
                        item={item}
                        userID={userID}
                        setUserID={setUserID}
                        selected={isMobile ? selected : null}
                      />
                    );
                  })}
              </div>
            </div>
            {data && (
              <Pagination
                totalItems={data?.total}
                currentPage={offset}
                onPageChange={(v) => {
                  setOffset(v);
                }}
              />
            )}
          </div>

          <div ref={selected}>
            <div className="selected">
              <h4>User Details</h4>
              <hr />

              {userDetails ? (
                userDetails && (
                  <SelectedUser
                    setLoading={setLoading}
                    userDetails={userDetails}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    url="auth"
                  />
                )
              ) : (
                <>
                  <p>Click on a user to see their details</p>
                </>
              )}
            </div>
          </div>
        </div>

        {loading && <WaveLoading />}
      </div>
      {clicked && (
        <Popup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </div>
  );
}

const Popup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [clevel, setCLevel] = useState(null);
  const [clevelname, setCLevelName] = useState(null);
  const [crole, setCRole] = useState(null);
  const [cgender, setCGender] = useState(null);
  const [field1Value, setField1Value] = useState("");
  const [field2Value, setField2Value] = useState(["Kirinyaga"]);
  const [roles, setRoles] = useState(["County Executive Committee Member",
  "Chief Officer",
  "County Director Agriculture",
  "County Director Livestock Veterinary and Fisheries",
  "CECM Office Administrator",
  "County SMS",
  "Head of Department",
  "CO Office Administrator",
  "Fleet Manager",
  "Head of CASIMU",
  "ICT/Admin",
  "Office Administrator",
  "Driver",
  "Support Staff"
]);
  // const roles = ["Enumerator", "Field Officer", "Supervisor"];

  const fname = useRef();
  const sname = useRef();
  const gender = useRef();
  const dpt = useRef();
  const pos = useRef();
  const role = useRef();
  const level = useRef();
  const levelname = useRef();
  const email = useRef();
  const phone = useRef();
  const password = useRef();
  const cpassword = useRef();

  function titleCase(str) {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  useEffect(() => {
    // Logic to update lavel name based on level
    if (clevel === "County") {
      setField2Value(["Kirinyaga"]);
      setRoles([
        "County Executive Committee Member",
        "Chief Officer",
        "County Director Agriculture",
        "County Director Livestock Veterinary and Fisheries",
        "CECM Office Administrator",
        "County SMS",
        "Head of Department",
        "CO Office Administrator",
        "Fleet Manager",
        "Head of CASIMU",
        "ICT/Admin",
        "Office Administrator",
        "Driver",
        "Support Staff"
      ]);
    } else if (clevel === "Sub County") {
      setField2Value([
        "Kirinyaga East",
        "Kirinyaga West",
        "Mwea East",
        "Mwea West",
        "Kirinyaga Central",
      ]);
      setRoles([
        "SCAO",
        "SCLPO",
        "SCVO",
        "SCFO",
        "Office Administrator",
        "Drivers",
        "Support Staff",
      ]);
    } else if (clevel === "Ward") {
      setField2Value([
        "Mutithi",
        "Kangai",
        "Wamumu",
        "Nyangati",
        "Murindiko",
        "Gathigiririr",
        "Teberer",
        "Kabare Baragwi",
        "Njukiini",
        "Ngariama",
        "Karumandi",
        "Mutira",
        "Kanyekini",
        "Kerugoya",
        "Inoi",
        "Mukure",
        "Kiine",
        "Kariti",
      ]);
      setRoles([
        "WAEO",
        "WLPO",
        "WAHO",
        "Meat Inspector"
      ]);
    }
  }, [clevel]);

  const handleField1Change = (e) => {
    setCLevel(e);
  };

  const handleField2Change = (e) => {
    setCLevelName(e);
  };

  const handleRoleChange = (e) => {
    setCRole(e);
  };

  const handleGenderChange = (e) => {
    setCGender(e);
  };
  const createUser = () => {
    const body = {
      Name:
        titleCase(fname.current.getValue().trim()) +
        " " +
        titleCase(sname.current.getValue().trim()),
      Phone: phone.current.getValue(),
      Email: email.current.getValue().toLowerCase().trim(),
      Role: role.current.getValue(),
      Position: pos.current.getValue(),
      Department: dpt.current.getValue(),
      Level: level.current.getValue(),
      LevelName: levelname.current.getValue(),
      Gender: gender.current.getValue(),
      Password: password.current.getValue(),
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      if (
        !validatePassword(body.Password) ||
        !validatePassword(cpassword.current.getValue())
      ) {
        result = false;
        setError("Password must be at least 6 characters!");
        setLoading(false);
        return result;
      }
      if (body.Password !== cpassword.current.getValue()) {
        result = false;
        setError("Passwords do not match!!!");
        setLoading(false);
        return result;
      }
      if (!body.Phone || body.Phone.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }
      if (fname.current.getValue() === "" || sname.current.getValue() === "") {
        result = false;
        setError("Two names are required!");
        setLoading(false);
        return result;
      }

      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/auth/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setClicked(false);
              props.setRefresh(!props.refresh);
            }, 2000);
          } else {
            console.log(data.error);
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>New User</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <Input ref={fname} type="text" label="First Name *" />
              <Input ref={sname} type="text" label="Surname *" />
            </div>
            <div className="div2equal">
              <Input ref={email} type="email" label="Email *" />
              <Input ref={phone} type="number" label="Phone *" />
            </div>

            <div className="div2equal">
              <Input ref={dpt} type="text" label="Department *" />
              <Input ref={pos} type="text" label="Designation *" />
            </div>

            <div className="div2equal">
              <UserSelect
                ref={level}
                label="Level *"
                value={field1Value}
                data={["County", "Sub County", "Ward"]}
                onChange={handleField1Change}
              />
              <UserSelect
                ref={levelname}
                label="Level Name*"
                data={field2Value}
                onChange={handleField2Change}
              />
            </div>

            <div className="div2equal">
              <UserSelect
                ref={role}
                label="Role *"
                data={roles}
                onChange={handleRoleChange}
              />
              <UserSelect
                ref={gender}
                label="Gender *"
                data={["Male", "Female"]}
                onChange={handleGenderChange}
              />
            </div>

            <div className="div2equal">
              <Input ref={password} type="password" label="Password *" />
              <Input
                ref={cpassword}
                type="password"
                label="Confirm Password *"
              />
            </div>
            <h6>{error}</h6>
            <Button handleClick={createUser} value="Submit" />
          </form>
          {loading && <WaveLoading />}
        </div>
      </div>
    </div>
  );
};
