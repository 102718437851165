import { useState } from "react";
import "../../Styles/portalupdates.scss";
import NewPost from "./NewsPost";
import NewStakeholder from "./NewStakeholder";
import NewProject from "./NewProject";

export default function PortalUpdates(props) {
  const [active, setActive] = useState("News");

  const Item = (params) => {
    return (
      <h4
        onClick={() => setActive(params.txt)}
        className={active === params.txt ? "active" : ""}
      >
        {params.txt}
      </h4>
    );
  };
  return (
    <div className="updates">
      <div className="bar">
        <div className="container">
          <Item txt="News" />
          <Item txt="Stakeholders" />
          <Item txt="Projects" />
        </div>
      </div>

      <div>
        {active == "News" && <NewPost />}
        {active == "Stakeholders" && <NewStakeholder />}
        {active == "Projects" && <NewProject />}
      </div>
    </div>
  );
}
