import { useState } from "react";
import MyMap from "./MyMap";
import NewWorkplan from "./NewWorkplan";
import Reports from "./Reports";
import Workplan from "./Workplan";

export default function ReportsHome(props) {
  const [active, setActive] = useState("Reports");

  const Item = (params) => {
    return (
      <h4
        onClick={() => {
          setActive(params.txt);
        }}
        className={active == params.txt ? "active" : ""}
      >
        {params.txt}
      </h4>
    );
  };

  return (
    <div className="reports">
      <div className="bar">
        <div className="container">
          <Item txt="Workplan" />
          <Item txt="New" />
          <Item txt="Reports" />
          <Item txt="Map" />
        </div>
      </div>
      <div>
        {active == "Workplan" && <Workplan />}
        {active == "New" && <NewWorkplan />}
        {active == "Reports" && <Reports />}
        {active == "Map" && <MyMap />}
      </div>
    </div>
  );
}
