import { useRef, useState } from "react";
import Button from "../Util/Button";
import Loading from "../Util/Loading";
import Input from "../Util/Input";
import Select from "../Util/Select";

export default function NewProject(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const title = useRef();
  const image = useRef();
  const type = useRef();
  const description = useRef();
  const link = useRef();

  const AddProject = () => {
    const body = {
      Title: title.current.value,
      Image: image.current.value,
      Type: type.current.value,
      Description: description.current.value,
      Link: link.current.value,
    };
    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (!body.Title) {
        result = false;
        setError("Please Enter a title!");
        setLoading(false);
        return result;
      }

      if (!body.Type) {
        result = false;
        setError("Type is required!");
        setLoading(false);
        return result;
      }
      if (!body.Description) {
        result = false;
        setError("Write a description!");
        setLoading(false);
        return result;
      }
      if (!body.Link) {
        result = false;
        setError("Link is required!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      const formData = new FormData();

      for (const i in body) {
        formData.append(i, body[i]);
      }

      fetch(`/api/projects/create`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="create">
      <div className="list">
        <h3>New Project</h3>
        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input ref={title} type="text" label="Name " required=" *" />
            <div className="input">
              <h4>
                Add Image<span> *</span>
              </h4>
              <Input
                ref={image}
                type="file"
                required=" *"
                accept="image/png, image/gif, image/jpeg"
              />
            </div>
            <Select
              ref={type}
              label="Type "
              required=" *"
              data={[
                "Internal Projects",
                "Partner Projects",
                "National GVT Projects",
                "Donor Projects",
                "NGOs Projects",
                "Others",
              ]}
            />
            <div className="input">
              <Input
                ref={description}
                type="textarea"
                label="Description "
                required=" *"
              />
            </div>
            <Input ref={link} type="text" label="Link " required=" *" />
            <h6>{error}</h6>
            <Button handleClick={AddProject} value="Submit" required=" *" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
}
