import React from "react";

export default class UserInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      inputValue: "",
    };

    this.input = React.createRef();
    this.getValue = this.getValue.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getValue() {
    return this.input.current.value;
  }

  handleFocus() {
    this.setState({ isFocused: true });
  }

  handleBlur() {
    this.setState({ isFocused: false });
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState({ inputValue: value });
    if (this.props.handleChange) {
      this.props.handleChange(value);
    }
  }

  focusInput() {
    this.input.current.focus();
  }

  render() {
    const { isFocused, inputValue } = this.state;

    return (
      <div className={`input-container ${isFocused ? "focused" : ""}`}>
        <h4 className={`label ${isFocused || inputValue !== "" ? "up" : ""}`}>
          {this.props.label}
        </h4>
        <input
          role="presentation"
          className="input"
          ref={this.input}
          type={this.props.type}
          placeholder={this.props.placeholder}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          value={inputValue}
          required
          autoComplete="off"
        />
      </div>
    );
  }
}
