import { useEffect, useRef, useState } from "react";
import "../../Styles/reports.scss";
import WaveLoading from "../Util/WaveLoading";
import Input from "../Util/Input";
import Select from "../Util/Select";

export default function NewWorkplan(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const subc = {
    "":[""],
    "Mwea West": ["Mutithi", "Kangai", "Thiba", "Wamumu"],
    "Mwea East": ["Nyangati", "Murinduko", "Gathigiriri", "Tebere"],
    "Kirinyaga East": ["Kabare", "Baragwi", "Njukiine", "Ngariama", "Karumandi"],
    "Kirinyaga West": ["Mukure", "Kiine", "Kariti"],
    "Kirinyaga Central": ["Mutira", "Kanyekini", "Kerugoya", "Inoi"],
  };
  const types = ["Extension Services", "Training", "Other"];
  const [selectedSubc, setSelectedSubc] = useState(Object.keys(subc)[0]);
  const [selectedLocation, setSelectedLocation] = useState(subc[selectedSubc][0]);

  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [su, setSU] = useState(null);
  const [sudata, setSUData] = useState(null);
  const tsk = useRef();
  const sc = useRef();
  const wd = useRef();
  const dt = useRef();
  const tp = useRef();
  const trg = useRef();

  const [body, setBody] = useState({
    Task: null,
    UserID: null,
    SupervisorID: null,
    Date: null,
    SubCounty: null,
    Ward: null,
    Type: null,
    Target: null,
  });
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const handleSubcChange = (e) => {
    setSelectedSubc(e.target.value);
    setSelectedLocation(subc[e.target.value][0]); // Reset selected location when subcounty changes
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  function searchByPhone(role, q) {
    fetch(`/api/mobile/seachbyphone/${role}/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        if (role === "Supervisor") {
          setSU(data);
        } else {
          setFO(data);
        }
      })
      .catch((e) => {});
  }

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Task = tsk.current.value;
    d.SubCounty = sc.current.value;
    d.Ward = wd.current.value;
    d.Date = dt.current.value;
    d.Type = tp.current.value;
    d.Target = trg.current.value;
    setBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === null || item == "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.Date === "") return setError("All fields are required!");
    setLoading(true);

    fetch("/api/workplan/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/es/Reports";
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div
        onClick={() => {
          if (params.type === "fo") {
            let d = body;
            d.UserID = params.item.UserID;
            setBody(d);
            setFOData(params.item);
            setFO(null);
          } else {
            let d = body;
            d.SupervisorID = params.item.UserID;
            setBody(d);
            setSUData(params.item);
            setSU(null);
          }
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Phone}
        </p>
      </div>
    );
  };

  return (
    <div className="list">
      <h3>Work Plan Item</h3>
      <hr />
      <div className="new">
        <form
          autoComplete="none"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input ref={tsk} type="text" label="Task *" />

          <div className="div3equal">
            <Input ref={dt} type="date" label="Date *" />
            <Select ref={tp} label="Select Type" data={types} />
            <Input ref={trg} value={1} type="number" label="Target Farmers *" />
          </div>

          <div className="div2equal">
            <div className="select">
              <label htmlFor="subcSelect">Select Subcounty:</label>
              <select id="subcSelect" ref={sc} value={selectedSubc} onChange={handleSubcChange}>
                {Object.keys(subc).map((subcounty) => (
                  <option key={subcounty} value={subcounty}>
                    {subcounty}
                  </option>
                ))}
              </select>
            </div>
            <div className="select">
              <label htmlFor="locationSelect">Select Location:</label>
              <select id="locationSelect" ref={wd} value={selectedLocation} onChange={handleLocationChange}>
                {subc[selectedSubc].map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <br></br>
          <h3>Select Field Officer *</h3>
          <hr />
          <div className="div2equal">
            <div className="search">
              <Input
                type="number"
                handleChange={(e) => {
                  searchByPhone("Field Officer", e);
                }}
                label="Phone Number"
                hint="Type to search"
              />
              {fo && (
                <div className="search_list">
                  {fo.map((item, i) => {
                    return <List key={i} item={item} type="fo" />;
                  })}
                </div>
              )}
            </div>

            <div>
              {fodata && (
                <Item
                  title="Selected Field Officer"
                  clearEvent={() => {
                    let d = body;
                    d.UserID = null;
                    setBody(d);
                    setFOData(null);
                  }}
                  data={fodata}
                />
              )}
            </div>
          </div>
          <br></br>
          <br></br>
          <h3>Assign Supervisor *</h3>
          <hr />
          <div className="div2equal">
            <div className="search">
              <Input
                type="number"
                handleChange={(e) => {
                  searchByPhone("Supervisor", e);
                }}
                label="Phone Number"
                hint="Type to search"
              />
              {su && (
                <div className="search_list">
                  {su.map((item, i) => {
                    return <List key={i} item={item} type="su" />;
                  })}
                </div>
              )}
            </div>

            <div>
              {sudata && (
                <Item
                  title="Selected Supervisor"
                  clearEvent={() => {
                    let d = body;
                    d.SupervisorID = null;
                    setBody(d);
                    setSUData(null);
                  }}
                  data={sudata}
                />
              )}
            </div>
          </div>

          <br />
          <h6>{error}</h6>
          <br />
          <button
            onClick={() => {
              createDocument();
            }}
          >
            Submit
          </button>
        </form>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div
            onClick={() => {
              props.clearEvent();
            }}
            className="fa fa-times"
          ></div>
          <h3>{props.title}</h3>
          <hr />
          <p>{props.data.Name}</p>
          <p>{props.data.Phone}</p>
        </div>
      )}
    </>
  );
};
