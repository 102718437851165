import { useState } from "react";
import MyMap from "./MyMap";
import Reports from "./Reports";

export default function ActivitiesHome(props) {
  const [active, setActive] = useState("List");

  const Item = (params) => {
    return (
      <h4
        onClick={() => {
          setActive(params.txt);
        }}
        className={active == params.txt ? "active" : ""}
      >
        {params.txt}
      </h4>
    );
  };

  return (
    <div className="reports">
      <div className="bar">
        <div className="container">
          <Item txt="List" />
          <Item txt="Map" />
        </div>
      </div>
      <div>
        {active == "List" && <Reports />}
        {active == "Map" && <MyMap />}
      </div>
    </div>
  );
}
